import * as React from "react";
import { useState, useEffect } from "react";

import "../App.css";

// markup
const PrivacyPage = () => {
  return (
    <div className="App">
      <header className="App-header">{/* <p>Affected Regions</p> */}</header>
      <div className="topPart">
        <div className="topTextWrap">
          <div className="flexspacer"></div>
          <p className="bigTitleText">Privacy Policy</p>
          <div>
            <a className="backButton" href="/">
              <span>&larr; Home</span>
            </a>
            <a className="backButton" href="/terms">
              <span>Terms &amp; Conditions</span>
            </a>
          </div>
          <p className="bigBodyText">
            We do not collect any data or track anybody.
          </p>
          <div className="flexspacer"></div>
        </div>
        <div className="topImageWrap"></div>
      </div>

      <footer>
        <a href="https://paperboat.io" className="footerLink">
          <div>
            <img
              src={require("../images/paperboat_logo.svg").default}
              className="footerpblogo"
              alt="Paperboat Logo"
            />
            <span className="footerpbcopy">&copy;</span> paperboat
          </div>
        </a>
        <div>
          <a href="/">Home</a>
          <a href="/privacy/">Privacy Policy</a>
          <a href="/terms/">Terms &amp; Conditions</a>
        </div>
      </footer>
    </div>
  );
};

export default PrivacyPage;
